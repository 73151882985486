.map-container {
    position: relative;
    overflow: hidden;
}

.btn-collapse {
    position: absolute;
    right: -48px;
    border-radius: 0px 5px 5px 0px;
    top: 50%;
    background: #f3f4f7;
    border: 0px;
    border-right: 1px solid rgba(167, 167, 167, 0.2);
    border-top: 1px solid rgba(167, 167, 167, 0.2);
    border-bottom: 1px solid rgba(167, 167, 167, 0.2);
}

.layers-box {
    background: #f3f4f7;
    padding: 0px 20px 0px 0px;
    position: absolute;
    width: 350px;
    min-height: 100%;
    z-index: 3;
    top: 0px;
    box-shadow: 1px 0.05rem 0.01rem rgba(167, 167, 167, 0.2);
    height: 100%;
    left: 0px;
}

.layer-box-body{
    max-height: 90%;
    overflow-y: auto;
}

.base-maps-box {
    position: absolute;
    right: 10px;
    top: 60px;
    z-index: 1;
}

.ol-zoom-extent {
    position: absolute;
    top: 10px;
    right: 80px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.ol-zoom-extent button {
    padding: 1px 10px;
    border: 0px;
    border-radius: 0.3rem;
    color: #6c757d;
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.2);
}

.ol-zoom {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.ol-zoom-in {
    padding: 0px 7px;
    height: 23px;
    border: 0px;
    border-radius: 0.3rem;
    color: #6c757d;
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.2);
}

.ol-zoom-out {
    padding: 0px 7px;
    height: 23px;
    margin-left: 5px;
    border: 0px;
    border-radius: 0.3rem;
    color: #6c757d;
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.2);
}

.symbology-box {
    cursor: move;
    z-index: 2;
    border: 1px solid #222;
    position: absolute;
    background: white;
    width: 200px;
    top: 120px;
    right: 10px;
    margin: 0px;
    overflow: auto;
    max-height: 400px;
}

.ol-attribution {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 20px;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: right;
}

.ol-attribution ul {
    height: 10px;
    padding-top: 4px;
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
}

.ol-attribution ul li {
    font-size: 12px;
}

.ol-attribution button {
    display: none;
}

.ol-mouse-position {
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.btn-print {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 130px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.btn-print button {
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.2);
}


.btn-line-measure {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 180px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.btn-measure button {
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.2);
}

.btn-polygon-measure {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 230px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.btn-catalogue{
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 280px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.btn-clean-map{
    z-index: 1;
    position: absolute;
    bottom: 30px;
    right: 10px;
    background: #f3f4f7;
    border-radius: 0.3rem;
    padding: 6px 6px;
}

.ol-buttons {
    padding: 0px 5px;
    font-size: 14px;
}

.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
}

.ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.ol-tooltip-static {
    background-color: #FFC107;
    color: #212121;
    border: 2px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.ol-tooltip-static:before {
    border-top-color: #FFC107;
}

.ol-overviewmap {
    left: 0.5em;
    bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
    bottom: 0;
    left: 0;
    border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
    display: block;
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #7b98bc;
    height: 150px;
    margin: 2px;
    width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 2px;
    left: 2px;
    position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
    display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
    border: 2px dotted rgba(0, 60, 136, 0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
    cursor: move;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "✖";
}

.popup-content{
    overflow: auto;
    max-height: 230px;
}

.dot {
    color: #f3f4f7;
    display: flex;
    margin-right: 10px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.dot.size-sm {
    font-size: 16px;
    height: 35px;
    width: 35px;
}

.dot.size-md {
    font-size: 24px;
    height: 70px;
    width: 70px;
}



.react-bootstrap-table table {
    table-layout: auto !important;
}

.mobile-logo{
    display: none;
    
}

@media (max-width: 800px) {
    .mobile-logo{
        display: block;
    }
}

.container-navbar-buttons{
    position: absolute;
    right: 20px;
}